<template>
  <div class="p-4" v-show="PageTitle">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Browse
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h4>
      {{ PageTitle }}
    </h4>
    <hr class="separate-line" />
    <router-link :to="{ name: 'SatPracticeDetailAllPDF', query: $route.query }">
      <el-button type="success" style="width: 100%;">
        <i class="fas fa-eye"></i>
        View PDF
      </el-button>
    </router-link>
    <QuestionsTable
      :showQuestion="true"
      :showPassage="true"
      :showOptions="true"
      :showAnswer="true"
      :showExplanation="false"
      :showLabels="false"
      :questions="showQuestions"
      :search="$route.query.search"
      :isAdmin="isRoleAdmin()"
      :showOrder="true"
    >
    </QuestionsTable>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import $ from "jquery";
import roleMixin from "@/mixins/role";
import QuestionsTable from "@/views/allQuestions/QuestionsTable.vue";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { QuestionsTable, Breadcrumb },

  mixins: [Common, roleMixin],

  props: [],
  data() {
    return {
      showPassage: true,
      showQuestion: true,
      showOptions: true,
      showAnswer: false,
      showExplanation: false,
      showLabels: false,
      showCreateNewQuestionDialog: false,
      practice: {
        exam: {
          title: null,
          exam_questions: []
        },
        subject_type: null,
        subject_id: null
      },
      showQuestions: [],
      practices: [],
      subjects: []
    };
  },
  computed: {
    PageTitle() {
      let title = this.practice.exam.title ? this.practice.exam.title : "";
      return title;
    },
    id() {
      return this.$route.query.id;
    },
    isEdit() {
      let isEdit = 0;
      if (this.$route.query.isEdit) {
        isEdit = Number(this.$route.query.isEdit);
      }
      return isEdit;
    }
  },
  watch: {
    showPassage() {
      this.initMathJaxPlugin();
    },
    showExplanation() {
      this.initMathJaxPlugin();
    },
    showAnswer() {
      if (this.showAnswer) {
        this.showOptions = true;
      }
    },
    showOptions() {
      this.initMathJaxPlugin();
    },
    showQuestion() {
      this.initMathJaxPlugin();
    },
    questions() {
      this.initMathJaxPlugin();
    }
  },

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    this.getPractices();
    if (this.id && Number(this.id) > 0) {
      await this.getPracticeDetail();
      await this.initMathJaxPlugin();
    } else {
      this.isEdit = true;
    }
    if (this.$route.query.subject_id) {
      this.practice.subject_id = this.$router.query.subject_id;
    }
    this.$nextTick(async () => {
      this.setImg();
    });
    let vm = this;
    window.onkeydown = function(e) {
      // 屏蔽ctrl+p 打印
      if (e.ctrlKey && e.keyCode == 80) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    };
  },

  methods: {
    setImg() {
      $(".small img").each(function() {
        if ($(this).attr("src") !== "") {
          $(this).attr("src", $(this).attr("src") + "?" + new Date().getTime());
        }
        $(this).attr("crossorigin", "anonymous");
      });
    },
    isSplit(nodes, index, pageHeight) {
      return (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      );
    },

    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-all"]]);
      });
    },
    async getPractices() {
      const res = await SAT.getPractices();
      this.practices = res.practices;
    },
    async getPracticeDetail() {
      const res = await SAT.getEditorPracticeDetail(this.id);
      this.practice = res.practice;
      this.practice.exam.exam_questions.forEach((question, index) => {
        this.showQuestions.push(question.question);
      });
    },
    showSubject(id) {
      let subjectName = "";
      this.subjects.forEach(subject => {
        if (subject.id == id) {
          subjectName = this.titleCase(subject.name);
        }
      });
      return subjectName;
    }
  }
};
</script>

<style scoped>
#content {
  position: relative;
  width: 1184.56px;
  margin: 0 auto;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>
